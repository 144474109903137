.c-langswitcher {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    display: flex;

    min-width: 89px;
    text-transform: uppercase;
    text-align: center;

    @include mq(bp4) {
        width: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        flex-direction: column;
        text-align: right;
        min-width: 100px;
        z-index: -1;
    }

    &.c-langswitcher--dropdown {
        top: 0;
        flex-direction: column-reverse;
        bottom: auto;
    }

    box-shadow: 0 -1px 5px -5px rgba(0,0,0,0.75);

    .c-home & {
        box-shadow: 0 0 5px -5px rgba(0,0,0,0.75);
    }

}



.c-langswitcher__item {
    // display: inline-block;
    margin-left: 0;
    display: flex;
    flex-grow: 1;
    text-align: center;

    background: linear-gradient(to top, palette(color-background,light) 50%, palette(color-background, x-light) 50%);
    // background-position: left bottom;
    background-size: 100% 200%;
    background-position: left bottom;
    transition: all $animation-duration-beta $animation-easing-alpha;

    .c-home & {
        background: linear-gradient(to bottom, palette(color-background, light) 50%, palette(color-background) 50%);
        background-size: 100% 200%;
        background-position: left top;
        transition: all $animation-duration-beta $animation-easing-alpha;
    }

    .c-home &:hover,
    .c-home &:focus,
    .c-home &:active {
        background-position: left bottom;
    }


    @include mq(bp4) {
        display: block;

        &.is-active {
            order: 1;
        }
    }
}

.c-langswitcher__link {
    padding: 1rem 1.5rem;
    display: inline-block;
    flex-grow: 1;
    text-align: center;
    font-family: $beta-font-family;

    @include text-dimensions($ms-2, 1);

    &.is-active {
        text-decoration: none;
        color: palette(color-beta);
    }

    &:visited {
        color: palette(color-beta);
    }

    &:focus,
    &:hover,
    &:active {
        outline: 0;
        text-decoration: none;
        color: palette(color-gamma);
    }

    @include mq(bp4) {
        @include padding-tb(1.75,1.75);
        @include padding-lr(1,1);
    }
}
