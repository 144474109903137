.c-gallery {
    position: fixed;
    background-color: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: none;
    z-index: 999;

    &.is-visible {
        display: flex;
        justify-content: center;
    }
}

.c-gallery__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}


.c-gallery__picture-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    // max-height: 90%;
    // max-width: 80%;
    max-height: 100vh;
    max-width: 100vw;

    .u-close {
        position: absolute;
        right: 0;
        top: 0;
        transform: none;
    }

    @include mq(bp3) {
        // border: 1px solid red;
        max-height: 80vh;
        max-width: 80vw;
    }
}

.c-gallery__nav-group {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    // justify-content: center;
    width: 100%;
    // transform: translateY(-50%);
    // bottom: 50%;
}

.c-gallery__nav {
    // position: absolute;
    // background: black;
    padding: 1rem;
    cursor: pointer;
    // right: 0;
    text-align: center;
    background: palette(color-background,light65);
    padding: .5rem 1rem;
    transition: all .2s ease;
    @include text-dimensions($ms-2, $small-line-height);

    // margin: 0 1rem;
    // @include margin-bottom(1);
    // @include margin-left(1);
    // @include margin-right(1);
    span {
        user-select: none;
    }

    &:hover {
        background: palette(color-background,light);
    }
}

.c-gallery__picture {
    border: 0;
    // border-radius: .5rem;
    // object-fit: contain;
    transform: none !important;
    max-height: 100vh;
    max-width: 100vw;



    @include mq(bp3) {
        border: 1rem solid rgba(250,250,250,0.95);
        // border: 1px solid red;
        max-height: 80vh;
        max-width: 80vw;
    }


}
