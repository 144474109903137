/*
    Components: Hero
    ---
*/
.c-hero,
.c-content__body {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 250px;
    // height: 100vh;
    // height: calc(100vh - 197px);
    height: calc(100vh - 269px);
    background-color: palette(color-background);

    @include mq(bp1) {
        height: calc(100vh - 197px);
    }

    @include mq(bp4) {
        // height: calc(100vh - 80px);
        height: calc(100vh - 220px);
        justify-content: space-around;
    }

    @include mq(bp5) {
        // height: calc(100vh - 90px);
        height: calc(100vh - 247.5px);
    }

    .c-home & {
        height: 100vh;

        @include mq(bp4) {
            height: calc(100vh - 80px);
        }

        @include mq(bp5) {
            height: calc(100vh - 90px);
        }
    }
}


.c-hero--portfolio {
    min-height: 250px;
    height: calc(100vh - 137px);

    @include mq(bp3) {
        min-height: 250px;
        height: calc(100vh - 160px);
    }

    @include mq(bp5) {
        min-height: 250px;
        height: calc(100vh - 180px);
    }
}



.c-hero__title {
    color: #fff;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include padding-lr(1.5);

    width: 100%;
    z-index: 5;

    @include mq(bp2) {
        right: 7%;
        width: auto;
        @include padding-lr(0);
    }

    @include mq(bp3) {
        right: 15%;
    }

    &.c-hero--jp {
        right: 25%;

        // left: unset;
        // right: 25%;
        // transform: translate(-50%, 0);
        // width: 100%;
        @include mq(bp3,max) {
            width: auto;
            right: unset;
            left: 50%;
            transform: translate(-50%,-50%);

        }

        // .c-title-site {
        //     @include text-dimensions($ms9,2.5);
        //
        //     @include mq(bp2, max) {
        //         @include text-dimensions($ms7,1.5);
        //     }
        // }

        // .c-title-tagline {
        //     font-family: $alpha-font-family;
        //
        //     @include mq(bp2, max) {
        //         @include text-dimensions($ms2,.75);
        //     }
        // }
    }

}

    .c-title-site {
        margin-bottom: .5rem;
        @include text-dimensions($ms8,2.5);

        :lang(ja) & {
            font-family: $beta-font-family;
            // letter-spacing: -.02em;
        }

        @include mq(bp2, max) {
            @include text-dimensions($ms6,1.5);
        }

        .c-hero--jp & {
            @include text-dimensions($ms9,2.5);

            @include mq(bp2, max) {
                @include text-dimensions($ms7,2);
            }
        }
    }

    .c-title-tagline {
        font-family: $beta-font-family;
        margin-bottom: 0;
        margin-left: .25rem;
        font-weight: $alpha-font-weight;

        @include text-dimensions($ms2,1.2);
        letter-spacing: -.02em;;

        @include mq(bp2, max) {
            @include text-dimensions($ms0,.75);
        }

        // .c-hero--jp & {
        //     font-family: $beta-jp-font-family;
        //     letter-spacing: $alpha-jp-letter-spacing;
        //
        //     @include mq(bp2, max) {
        //         @include text-dimensions($ms2,1.25);
        //     }
        // }

        :lang(ja) & {
            font-family: $beta-jp-font-family;
            // letter-spacing: $alpha-jp-letter-spacing;
            // font-weight: $alpha-font-weight;
        }
    }


.c-hero__image {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 100%;

    @include mq(bp3) {
        border: 13px solid #fff;
        max-width: none;
        width: 90%;
        height: 80%;
        margin: auto;
    }

    .c-ratio-image {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right bottom, rgba(0,0,0,1) 1%, rgba(0,0,0,.2) 30%, rgba(0,0,0,.2) 75%, rgba(0,0,0,1) 99%);
        }
    }
}
