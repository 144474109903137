/*
    Components: Cards
    ---
    A card displays site content in a manner similar to a playing card.
*/

.c-card {
    @include padding-tb(1,1);
    @include padding-lr(1,1);
    @include margin-bottom(1);

    :lang(ja) & p {
        max-width: 40rem;
    }

    max-width: 49rem;
    margin-left: auto;
    margin-right: auto;

    
}

.c-card__body-custom {
    li { 
        ul, ol {
            margin-bottom: 1rem !important;
        }
    }
}

.c-card--centered {
    :lang(ja) & p {
        margin-left: auto;
        margin-right: auto;
    }

    .c-card__title {
        :lang(ja) & {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
    // Reset bottom margins on children to make equal paddings possible
    .c-card > *:last-child,
    .c-card > *:last-child > *:last-child,
    .c-card > *:last-child > *:last-child > *:last-child {
        margin-bottom: 0;
    }


    .c-card__title {
        color: palette(color-beta, dark);
        margin-bottom: 0;

        :lang(ja) & {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-card__body {
        p {
            margin-bottom: 1rem;

            :lang(ja) & {
                margin-left: auto;
                margin-right: auto;
            }
        }

    }


    .c-card__meta {
        margin-bottom: 1.2rem;
        color: palette(color-beta);

        ul {
            @include text-dimensions($small-font-size, $small-line-height);
            margin-bottom: 0;
        }

        :lang(ja) & {
            font-family: $alpha-jp-font-family;
            // letter-spacing: $alpha-jp-letter-spacing;
            // font-weight: $alpha-font-weight;

            max-width: 32rem;

            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-card__description {
        margin-bottom: 1rem;
    }

    .c-card__profile {
        @include text-dimensions($small-font-size, $small-line-height);
        text-transform: uppercase;
    }

    .c-card--monospace {
        font-family: $alpha-font-family;
    }


.c-card--alpha {
    color: palette(color-neutral,dark);
    background-color: palette(color-neutral,x-light);
    border: 0;
}

.c-card--beta {
    color: palette(color-gamma,dark);
    background-color: palette(color-background);
    border: 0;
}

.c-card--img {
    margin-bottom: 0;

    @include mq(bp3, max) {
        @include padding-lr(0);

        .c-page--about &{
            @include padding-lr(1);
        }
    }

    :lang(ja) & p {
        max-width: none;
    }
}

    .c-card--img-next {
        // background-image: url('/img/jpg/Creation/Wooden Plates/95.jpg');
        left: 100% !important;
        transition: all .3s ease;

        &.is-activated {
            left: 0 !important;
        }
    }

    .c-card--img-actual {
        transition: all .3s ease;
        left: 0 !important;

        &.is-deactivated {
            left: -100% !important;
        }
    }

    .c-card--conditions {
        p {
            :lang(ja) & {
                max-width: none;
                text-align: left;
            }
        }
        .c-card__title {
            :lang(ja) & {
                margin-left: 0;
                margin-right: 0;

                font-family: $alpha-jp-font-family;
                letter-spacing: 0;
            }
        }

        .c-work__description {
            :lang(ja) & {
                margin-left: 0;
                margin-right: 0;
                text-align: left;
            }
        }
    }

.c-card__expandable {
    position: relative;
    overflow: hidden;
    max-height: 300px;
    transition: max-height .5s ease-in-out;
    padding-bottom: 1rem;
    margin-bottom: 0;

    ul{
        margin-bottom: 1.2rem;
    }
    > *:not(p):nth-last-child(2){
        padding-bottom: 1.5rem;
    }

    &:after {
        content: '';
        width: 100%;
        // height: 100%;
        // height: 50px;
        position: absolute;
        left: 0;
        // top: 0
        // bottom: 0;
        display: block;
        // background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 90%, palette(color-background) 93%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 30%, #f7f3f0 70%);
        background-size: 100% 200%;
        background-position: left bottom;
        // transition: background-position .4s ease .1s;
        height: 70px;
        bottom: -.5rem;

    }

    .c-card__read-more {
        width: calc(100% - 1rem);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, palette(color-background) 28%);
        position: absolute;
        bottom: .05rem;
        left: 0;
        margin: 0 1rem;
        z-index: 1;
        padding: 0;
        // transition: padding .1s ease;

        &.c-card__read-close {
            position: relative;
            margin: 0;
            padding: 0;
        }

        :lang(ja) & {
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            line-height: 2;
            bottom: -.5rem;
            // padding-left: .5rem;
        }
    }



    .c-card__description-last {
        padding-bottom: 1rem;
    }

    .c-card__read-link {
        padding: .5rem 0;
    }

    &.is-open {
        // max-height: 1050px;
        // @include padding-bottom(0);

        &:after {
            // background-position: left top;
        }

        .c-card__read-more {
            // position: relative;
            // padding: .5rem 0;
            // margin: 0;
        }
    }

}

