/*
    Components: Navigation
    ---
    Site navigation, breadcrumbs, etc.
*/

.c-nav-main {
    width: 100%;
    font-family: $beta-font-family;
    text-transform: lowercase;
    order: 2;

    // position: absolute;
    // top: 137px;
    .c-nav-main__item.is-selected.has-children {
        margin-bottom: 0;
    }

    @include mq(bp1) {
        @include margin-top(0);
    }

    // @include mq(bp2) {
    //     position: relative;
    //     top: unset;
    // }

    @include mq(bp4) {
        order: 0;
        width: auto;

        // .c-nav-main__item.is-selected.has-children,
        // .c-nav-main__item.has-children.is-open-dropdown {
        .c-nav-main__item.is-selected.has-children {
            position: relative;
            margin-bottom: 80px;

            .c-home & {
                margin: 0;
            }
        }
    }

    @include mq(bp5) {
        // .c-nav-main__item.is-selected.has-children,
        // .c-nav-main__item.has-children.is-open-dropdown {
        .c-nav-main__item.is-selected.has-children {

            margin-bottom: 90px;

            .c-home & {
                margin: 0;
            }
        }
    }

    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
    }
}

    .c-nav-main__list {
        margin-bottom: 0;
        background-color: palette(color-background,x-light);
        z-index: 5;
        margin-right: 0;

        @include mq(bp1) {
            border-top: 1px dotted palette(color-neutral,light);
        }

        @include mq(bp4) {
            border-top: 0;

            // Just added
            // margin-right: -1rem;
            // This is the color bar seen as background of menu
            &.is-active-with-children:after {
                content: '';
                background-color: palette(color-background,light);
                width: 100%;
                position: absolute;
                left: 0;
                // height: 50%;
                z-index: -2;
                width: 100%;
                height: 50%;
                bottom: 0;





            }

            .c-home & {
                background-color: palette(color-background);

                &:after {
                    display: none;
                }
            }
        }

        @include mq(bp5) {
            // Just added
            margin-right: -2rem;
        }

    }


    .c-nav-main--mobile {
        background-color: palette(color-background);
        display: none;

        &.is-visible {
            display: block;
        }
    }

    .c-nav-main__item {
        transition: .2s all ease-in;

        & + & {
            border-top: 1px dotted palette(color-neutral,light);
        }

        @include mq(bp4) {
            float: left;

            & + & {
                border-top: 0;
            }
        }
    }

    .has-children {
        @include mq(bp4) {
            display: flex;
            align-items: center;

            & > .c-nav-main__link:after {
                content: '';
                // position: absolute;
                // top: 50%;
                // right: .75rem;
                // transform: translateY(-50%);
                width: 11px;
                height: 6px;
                background: url('../img/svg/icon-dropdown.svg');
                background-size:cover;
                margin-bottom: 2px;
                display: inline-block;
                transition: .2s all ease-in;

                .c-home & {
                    transform: rotate(180deg);
                }
            }

            &.is-selected {
                & > .c-nav-main__link:after {
                    background: none;
                    width: 0;
                }
            }

            &.is-open-dropdown .c-nav-main__link:after {
                transform: rotate(180deg);

                .c-home & {
                    transform: rotate(0deg);
                }
            }

            .c-home & {
                position: relative;
            }
        }
    }

    .c-nav-main__link {
        display: block;
        color: palette(color-gamma);
        text-decoration: none;
        font-weight: $alpha-font-weight;

        background: linear-gradient(to top, palette(color-background,light) 50%, palette(color-background, x-light) 50%);

        background-size: 100% 200%;
        background-position: left top;
        transition: all $animation-duration-beta $animation-easing-alpha;

        @include text-dimensions($alpha-font-size,1,0,.25,.25);

        @include padding-lr(.75,.75);
        // @include padding-lr(.35,.35);
        @include padding-tb(.75,.75);

        @include mq(bp4) {
            @include padding-tb(1.75,1.75);
            @include padding-lr(1,1);
        }



        &:visited {
            color: palette(color-gamma);
        }

        &:hover,
        &:active,
        &:focus,
        .is-selected & {
            outline: 0;
            text-decoration: none;
            color: palette(color-beta,dark);
            background-position: left bottom;
        }

        .c-home & {
            background: linear-gradient(to bottom, palette(color-background) 50%, palette(color-background, light) 50%);
            background-size: 100% 200%;
            background-position: left bottom;
            transition: all $animation-duration-beta $animation-easing-alpha;
            white-space: nowrap;
        }

        .c-home &:hover,
        .c-home &:active {
            background-position: left top;
        }


        .is-selected > & {
            background-color: palette(color-background,light);
            color: palette(color-alpha);
        }

        .c-nav-main--home & {
            color: palette(color-beta,dark);

            &:hover,
            &:active {
                text-decoration: none;
                color: palette(color-alpha);
                background-color: palette(color-background);
            }
        }
    }

    .c-nav-sub__link {
        background: linear-gradient(to top, palette(color-background) 50%, palette(color-background, light) 50%);
        background-size: 100% 200%;
        background-position: left top;
        transition: all $animation-duration-beta $animation-easing-alpha;


        .is-selected > &,
        &:hover,
        &:active {
            background-position: left bottom;
        }

    }


        .c-nav-sub__list {
            background-color: palette(color-background,light);
            z-index: 5;

            @include padding-left(2);

            // .c-nav-main__link {
            //     background: #fcfbfa; // @TODO Change!
            //
            //     .is-selected & {
            //         background: #f7f3f0; // @TODO Change!
            //     }
            // }


            // @include mq(bp4) {
            //     float: right;
            //
            //     // just added
            //     margin-right: calc(100px);
            //
            //     // This is the color bar seen as background of submenu
            //     &:after {
            //         content: '';
            //         background-color: palette(color-background,light);
            //         width: 100%;
            //         position: absolute;
            //         left: 0;
            //         height: 50%;
            //         z-index: -1;
            //
            //         // added as dropdown
            //         height: 100%;
            //     }
            // }
            //
            // @include mq(bp4) {
            //     margin-right: calc(100px - 2rem);
            //     // added as dropdown
            //     margin-right: 100px;
            // }

            @include mq(bp4) {
                margin: 0;
                padding: 0;
                [lang="en"] & {
                    margin-left: -49px;
                }
                [lang="nl"] & {
                    margin-left: 1px;
                }
            }

            @include mq(bp5) {
                [lang="en"] & {
                    margin-left: -67px;
                }
                [lang="nl"] & {
                    margin-left: -11px;
                }
            }

            .c-nav-main__link:after {
                background: none;
            }
        }

        .c-nav-subnav {
            // position: relative;
            height: auto;
            display: block;
            width: 100%;
            left: 0;

            transition: .2s all ease-in;
            top: 0;
            // max-height: 90px;
            width: auto;
            background-color: palette(color-background,light);

            // html[lang="en"] &{
            //     @include mq(bp4) {
            //         margin-left: -40px;
            //     }

            //     @include mq(bp5) {
            //         margin-left: -57px;
            //     }
            // }

            // html[lang="nl"] &{
            //     @include mq(bp4) {
            //         margin-left: -3px;
            //     }
            //     @include mq(bp5) {
            //         margin-left: -16px;
            //     }
            // }

            .is-open-dropdown & {
                z-index: 5;

                // html[lang="en"] &{
                //     @include mq(bp4) {
                //         margin-left: -29px;
                //     }

                //     @include mq(bp5) {
                //         margin-left: -47px;
                //     }
                // }

                // html[lang="nl"] &{
                //     @include mq(bp4) {
                //         margin-left: 0;
                //     }
                //     @include mq(bp5) {
                //         margin-left: -5px;
                //     }
                // }
            }

            .c-nav-main__item {
                .c-home & {
                    float: none;
                }
            }

            .c-nav-main__link {

                background: linear-gradient(to top, palette(color-background) 50%, palette(color-background, light) 50%);
                background-size: 100% 200%;
                background-position: left top;
                transition: all $animation-duration-beta $animation-easing-alpha;

                &:hover,
                &:active,
                &:focus {
                    outline: 0;
                    text-decoration: none;
                    color: palette(color-beta,dark);
                    background-position: left bottom;
                }

            }

            .is-selected > .c-nav-main__link {
                background: linear-gradient(to top, palette(color-background) 50%, palette(color-background) 50%);
                background-position: left bottom;
            }

            @include mq(bp4) {
                position: absolute;
                z-index: -1;

                .c-home & {
                    width: auto;
                }

                .is-open-dropdown &,
                .has-children.is-selected & {
                    top: 80px;
                    // max-height: 90px;

                    .c-home & {
                        // top: auto;
                        // bottom: 80px;
                        top: auto;
                        bottom: 80px;
                        position: absolute;
                        box-shadow: 0 -3px 6px -4px rgba(0,0,0,.75);
                    }

                }

                .c-nav-sub__list {
                    display: flex;
                    flex-wrap: nowrap;

                    .c-nav-main__item {
                        white-space: nowrap;
                    }

                    .c-home & {
                        display: block;
                    }
                }

            }

            @include mq(bp5) {
                .is-open-dropdown &,
                .has-children.is-selected & {
                    top: 90px;
                    // max-height: 90px;

                    .c-home & {
                        // top: auto;
                        // bottom: 90px;
                        top: auto;
                        bottom: 90px;
                        position: absolute;
                    }
                }

            }
        }

    .c-nav-main__lang {
        position: relative;
        min-width: 89px;
        text-transform: uppercase;
        text-align: center;
        z-index: 6;

        // just added
        box-shadow: 0 1px 5px -5px rgba(0,0,0,.75);

        @include mq(bp4) {
            min-width: 100px;
        }

        .c-nav-main__link {
            height: 100%;
            // line-height: 2;
            width: 100%;
            color: palette(color-beta);

            background: linear-gradient(to top, palette(color-background,light) 50%, palette(color-background, x-light) 50%);
            // color: palette(color-alpha);

            background-size: 100% 200%;
            background-position: left bottom;

            @include text-dimensions($ms-2, 1);

            .c-home & {
                background: linear-gradient(to bottom, palette(color-background) 50%, palette(color-background, light) 50%);
                background-size: 100% 200%;
                background-position: left top;
            }

            &:visited {
                color: palette(color-beta);
            }

            &:after {
                content: '';
                // position: absolute;
                // top: 50%;
                // right: .75rem;
                // transform: translateY(-50%);
                width: 11px;
                height: 6px;
                background: url('../img/svg/icon-dropdown.svg');
                background-size:cover;
                margin-bottom: 2px;
                display: inline-block;
                transition: .2s all ease-in;

                .c-home & {
                    transform: rotate(180deg);
                }
            }

            &.is-active:after {
                transform: rotate(180deg);

                .c-home & {
                    transform: rotate(0deg);
                }
            }

            span {
                margin-right: .6em;
            }
            // &:hover + .c-langswitcher {
            //     display: flex;
            // }
        }



        // .c-nav-main__link:hover,
        // .c-nav-main__link:active {
        //     text-decoration: none;
        //     border: 1px solid palette(color-alpha);
        // }

    }

.c-nav-main-trigger {
    user-select: none;
    position: relative;
    width: 100%;

    display: block;
    color: palette(color-alpha);
    background-color: palette(color-background,light);
    border: 0;
    border-top: 1px dashed palette(color-background);
    outline: 0;

    @include padding-tb(1,1);

    .c-symbol-close {
        display: none;
    }

    &.is-active {
        .c-symbol-hamburger {
            display: none;
        }

        .c-symbol-close {
            display: inline-block;
        }
    }

    @include mq(bp4) {
        display: none;
    }
}
