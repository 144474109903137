/*
Shame
---
Hacks and quick fixes
*/
// li {
//     float: left;
//     width: 33.3%;
//     padding: 0;
//     margin: 0;
//     background-color: #000;
//     border: 10px solid #fff;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }

.c-ratio-aspect .c-ratio-aspect__link {
    position: relative;
    height: 0;
    padding-top: 56.25%;

    &.u-pos-unset {
        position: unset;
    }
}

    .c-ratio-aspect__link {
        display: block;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
    }

.c-ratio-aspect .c-ratio-aspect__img {
    position: absolute;
    left: 50%;
    top: 50%;
    // transform: translate(-50%,-50%);
}

    .c-ratio-aspect__img {
        display: block;
        // max-width: 100%;
        // max-height: 100%;
        margin: auto;
        // transition: transform .5s ease-out;
        transform: translate(-50%,-50%);
        transform-origin: top left;

        &:hover,
        &:active {
            transform: translate(-50%,-50%);
        }

        &.u-zoom {
            position: fixed;
            max-width: 90%;
            max-height: 90%;
            z-index: 7;
            transform: translate(-50%,-50%);

            border: 1rem solid white;
            // border-bottom: 60px solid white;

            &:hover,
            &:active {
                transform: translate(-50%,-50%);
            }
        }
    }
//

.u-max-w-650 {
    width: 100%;
    max-width: 650px;
}

.c-form {
    width: 100%;

    @include mq(bp3) {
        width: 80%;
    }
}

.c-form__field {
    position: relative;
    margin-top: 1.7rem;

    & ~ & {
        margin-top: 2.75rem;
    }
}

// Home
.c-home {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .c-row {
        flex: 1 auto;
    }

    .c-content {
        order: 2;
        flex: 1 100%;
        width: 100%;
        position: absolute;
        z-index: 0;

        @include mq(bp4) {
            position: relative;
            order: -1;
        }
    }
}

.u-clickable {
    cursor: pointer;
}

.u-overflow-hidden {
    // overflow: hidden;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

.u-overlay {
    display: block;
    position: fixed;
    background: rgba(palette(color-background,dark), .85);
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 6;
}

// .u-zoom {
//     position: fixed;
//     width: 95%;
//     height: 95%;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-color: transparent;
//     z-index: 7;
//
//     ~ .u-overlay {
//         display: block;
//     }
// }

.u-close {
    z-index: 7;
    // display: block;
    position: fixed;
    // top: 10%;
    top: 2.5rem;
    right: 2.5rem;
    transform: translateX(-50%);
    // min-width: 50%;
    text-align: center;

    background: palette(color-background,light65);

    padding: .5rem 1rem;
    // box-shadow: 3px 0 4px 1px palette(color-background, dark);
    opacity: 1;
    transition: all .2s ease;
    transform-origin: top right;
    cursor: pointer;

    @include text-dimensions($ms-2, $small-line-height, $small-margin-bottom);


    &:hover,
    &:active {
        background: palette(color-background,light);
    }

    display: flex;
    align-items: center;
}

.u-close__word,
.u-close__x {
    display: inline-block;
}

.u-close__x {
    margin-left: .5rem;
}

.u-margin--auto {
    margin: auto;
}

.u-lang-ja {
    font-family: $beta-jp-font-family !important;
    // letter-spacing: $alpha-jp-letter-spacing !important;
    // font-weight: $alpha-font-weight !important;
}

.u-mb-90,
.is-open-dropdown {
    // margin-bottom: 90px;
}
