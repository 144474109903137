.c-colofon {
    font-family: $beta-font-family;
    padding: 1em 0;
    background-color: palette(color-background, xx-dark);
    @include text-dimensions($ms-2);
}

    .c-colofon__item {
        color: palette(color-neutral);
        display: inline-block;
        margin: 0 1rem;
    }

    .c-colofon__link {
        text-decoration: underline;
        color: palette(color-neutral);

        &:visited {
            color: palette(color-neutral);
        }

        &:hover,
        &:active,
        &:focus {
            color: palette(color-neutral, light);
        }


    }
