/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1:
 *
 *   <h2 class="u-h1"></h2>
 *
 */
// use: @include text-dimensions(#font-size, #line-height, #margin-bottom, #padding-top, #padding-bottom, #margin-top)

.u-h1 {
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;

    @include text-dimensions($h1-font-size, $h1-line-height);

    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h1-font-size * 1.2,$h1-line-height * 1.3);
    }
}

.u-h2 {
    font-family: $h2-font-family;
    font-weight: $h2-font-weight;

    @include text-dimensions($h2-font-size, $h2-line-height);
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h2-font-size * 1.2,$h2-line-height * 1.3);
        // text-align: justify;
        // max-width: 32rem;
    }
}

.u-h3 {
    font-family: $h3-font-family;
    font-weight: $h3-font-weight;

    @include text-dimensions($h3-font-size, $h3-line-height);
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h3-font-size * 1.2,$h3-line-height * 1.3);
        // text-align: justif * 1.3y;
        // max-width: 32rem;
    }
}

.u-h4 {
    font-family: $h4-font-family;
    font-weight: $h4-font-weight;

    @include text-dimensions($h4-font-size, $h4-line-height);
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h4-font-size * 1.2,$h4-line-height * 1.3);
        // text-align: justify;
        // max-width: 32rem;
    }
}

.u-h5 {
    font-family: $h5-font-family;
    font-weight: $h5-font-weight;

    @include text-dimensions($h5-font-size, $h5-line-height);
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h5-font-size * 1.2,$h5-line-height * 1.3);
        // text-align: justify;
        // max-width: 32rem;
    }
}

.u-h6 {
    font-family: $h6-font-family;
    font-weight: $h6-font-weight;

    @include text-dimensions($h6-font-size, $h6-line-height);
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($h6-font-size * 1.2,$h6-line-height * 1.3);
        // text-align: justify;
        // max-width: 32rem;
    }
}
