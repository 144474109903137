.c-highlight {
    // border: 1px dotted palette(color-beta, dark);
    // padding: .15rem .5rem .05rem;
    background-color: palette(color-neutral, light);
    // border-top: 1px dotted palette(color-beta, dark);
    color: palette(color-beta);
    font-weight: bold;
}


.c-highlight--notice {
    color: palette(color-alpha);
    padding: 10px;
    border: 1px dashed palette(color-alpha);
    max-width: 27rem !important;
    
    font-style: oblique;
    font-size: .9rem !important;
    line-height: 1.5 !important;
    letter-spacing: 0 !important;



}

.c-highligh--default-body {
    margin: 2rem auto 0;
    text-align: center;

    font-style: normal;
    font-size: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;

    &:first-child {
        margin-top: 0;
    }
    
    &:not(:lang(ja)) {
        font-style: oblique;
        font-size: .9rem !important;
        line-height: 1.5 !important;
        letter-spacing: 0 !important;
    }

}

