/*
    Elements: Page
    ---
    Default markup for page level elements
*/

html {
    font: $html-font-weight #{$html-font-size / 16 * 100%}/#{$html-line-height} $html-font-family;
    -webkit-overflow-scrolling: touch;
    background-color: $html-background-color;
    // scroll-behavior: smooth;
    // height: 100%;

    @if $html-responsive-font-size == true {
        @include mq($html-responsive-font-breakpoint) {
            font-size: $alpha-font-size / 16 * $html-responsive-font-magnification;
        }
    }
}

body {
    color: $body-color;
    // height: 100%;

    @if $body-font-smoothing == true {
        -webkit-font-smoothing: antialiased;
    }

    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        text-align: justify;

        // @include text-dimensions($ms2,1.25);
    }
}

::-moz-selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}

::selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}
