/*
    Components: Input
    ---
    Input styles & sizes.
*/

/**
 *  1. The appearance property defines how elements appear by default.
 *     By setting the value to none the default appearance can be entirely redefined using other CSS properties.
 *
 *  2. Controls the application of anti-aliasing when fonts are rendered.
 *     Both Webkit & Firefox implements.
 *     Works only on Mac OS X platform.
 */

// Input elements
.c-input-text {
    appearance: none; /* [1] */
    -webkit-font-smoothing: antialiased; /* [2] */
    -moz-osx-font-smoothing: grayscale; /* [2] */
    background-color: palette(color-neutral,x-light);
    border-radius: $radius-zero;
    border: 0;
    // border-bottom: $border-width $border-style palette(color-background, dark);
    color: palette(color-neutral,dark);
    font-family: $alpha-font-family;
    margin: 0;
    width: 100%;
    display: block;
    outline: none;
    line-height: normal;

    + .c-input-label__underline {
        position: absolute;
        height: calc(100% + 1.7rem);
        top: -1.7rem;

        span {
            display: block;
            position: absolute;
            @include padding-left(.65);
        }

        &:before {
            content: '';
            position: absolute;
            overflow: hidden;

            left: 0;
            width: 0;
            height: 100%;
            border-bottom: $border-width $border-style palette(color-alpha);

            transition: width $animation-duration-alpha $animation-easing-alpha;
        }
    }

    &:focus,
    &:active {
        + .c-input-label__underline {
            &:before {
                width: 100%;
            }
        }
    }


    &.has-error {
        border: 1px solid palette(color-error);

        &:focus {
            border-color: palette(color-error);
        }
    }

    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($ms0,1);
    }
}


// Input sizes
.c-input-text--sm {
    @include padding-lr(.5,.5);
    @include input-dimensions($ms-2,1,0,.5,.5);
}

.c-input-text--md {
    @include padding-lr(.75,.75);
    @include input-dimensions($ms-1,1,0,.75,.75);
}

.c-input-text--lg {
    @include padding-lr(1,1);
    @include input-dimensions($alpha-font-size,1,0,1,1);
}

// Input modifiers
.c-input-text--prefix {
    border-radius: $radius 0 0 $radius;
    border-right: 0;
}

// Input option
.c-input-option {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}
