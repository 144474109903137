/*
    Components: Row
    ---
    Creates a horizontal row with padding
*/

.c-row {
    @include padding-tb($alpha-leading,0);
    @include padding-lr($columns-gutter,$columns-gutter);
}

.c-row--zero {
    @include padding-tb(0,0);
    @include padding-lr(0,0);

    @include mq(bp1) {
        @include padding-lr($columns-gutter,$columns-gutter);
    }
}

.c-row--portfolio {
    overflow-x: hidden;
}

.c-row--alpha {
    background-color: palette(color-neutral,x-light);
}

.c-row--beta {
    color: palette(color-neutral,x-dark);
}

// .c-row--bottom-fixed {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//
//     @include mq(bp3) {
//         position: relative;
//         bottom: unset;
//         width: auto;
//     }
// }
