/*
    Components: Forms
    ---
    Label styles
*/

// Labels
.c-label {
    color: palette(color-neutral);
    @include text-dimensions($ms-1,1,.25);

    &.has-error {
        color: palette(color-error);
    }

    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($ms0,1);
    }
}

.c-label--option {
    margin: 0;
    padding: 0;
    width: auto;
    display: inline-block;
}
