/*
    Components: Alerts
    ---
    Alerts: contextual feedback messages for typical user actions.
*/

/**
 *  1. The element is positioned relative to its normal position, so "left: 20" adds 20
 *     pixels to the element's LEFT position.
 *
 *
 *  Example:

    <div class="c-alert-box">
        <p>Text <a href="#" class="c-alert-box__link">link</a></p>
        <span class="c-alert-box__close js-hide">&times;</span>
    </div>
 *
 */

.c-alert-box {
    position: relative; /* [1] */
    border-radius: $radius;
    @include padding-top(1);
    @include padding-lr(1,1);
    @include margin-bottom(1);
}

    .c-alert-box__link {
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        .c-alert-box & {
            color: palette(color-neutral, dark);
        }

        .c-alert-box--error & {
            color: palette(color-neutral, x-light);
        }

        .c-alert-box--success & {
            color: palette(color-neutral, x-light);
        }

        .c-alert-box--notice & {
            color: palette(color-neutral, x-light);
        }
    }

    .c-alert-box__close {
        position: absolute;
        top: #{$alpha-line-height/6}em;
        right: #{$alpha-line-height/3}em;
        line-height: 1;
        text-decoration: none;
    }

.c-alert-box--error {
    color: palette(color-neutral, x-light);
    background-color: palette(color-error);
    border: $border-width $border-style palette(color-error, dark);
}

.c-alert-box--success {
    color: palette(color-neutral, x-light);
    background-color: palette(color-success);
    border: $border-width $border-style palette(color-success, dark);
}

.c-alert-box--notice {
    color: palette(color-neutral, x-light);
    background-color: palette(color-notice);
    border: $border-width $border-style palette(color-notice, dark);
}

.c-alert-inline {
    display: block;
    text-align: right;
    @include text-dimensions($ms-2,1);
}

.c-alert-inline--error {
    color: palette(color-error);
}

.c-alert-inline--success {
    color: palette(color-success);
}
