/*
Components: Definition Lists
---
The style of definition lists
*/
.c-def-list {
    @include margin-tb(2,2);
    padding: 0;
}

.c-def-list__title {
    font-family: $beta-font-family;
    color: palette(color-gamma);
    
    // @include text-dimensions($ms1);
    @include padding-top(.75);
    
    
    
    &:first-child {
        @include padding-top(0);
    }
    
    :lang(ja) & {
        font-family: $alpha-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        // @include text-dimensions($ms0,.5);
        @include margin-bottom(.75);
    }
    
    @include mq(bp3) {
        left: 0;
        top: 1.25rem;
        width: 6.5rem;
        text-align: right;
        line-height: 1rem;
        position: relative;
        
        :lang(ja) & {
            @include margin-bottom(0);
        }
    }
}

.c-def-list__description {
    max-width: 36rem;
    
    @include padding-left(0);
    @include margin-bottom(1);
    
    @include mq(bp3) {
        @include padding-left(1);
        @include margin-left(7.75);
        @include padding-bottom(0);
        border-left: 1px solid palette(color-background, dark);
    }
    
    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        text-align: justify;
        
        max-width: auto;
        
        @include mq(bp3) {
            max-width: 36rem;
            // @include margin-left(10);
        }
    }
}

.c-def-list--beta {
    margin: .5rem auto .75rem;
    line-height: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // width: 100%;
    
    @include mq(bp3) {
        width: auto;
    }
    
    .c-def-list__title {
        position: inherit;
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: .075rem;
        font-family: $alpha-font-family;
        text-align: left;// marcellus
        
        width: 100%;
        // @include margin-bottom(.5);
        
        @include mq(bp4) {
            text-align: left;
            width: 6.5rem;
        }
        
        :lang(ja) & {
            font-family: $alpha-jp-font-family;
            // letter-spacing: $alpha-jp-letter-spacing;
            // font-weight: $alpha-font-weight;
            width: auto;
            
            // @include text-dimensions($ms1,2);
            @include margin-top(.5);
        }
        
    }
    
    .c-def-list__description {
        border-left: 0;
        font-family: $beta-font-family;
        margin: 0;
        padding-left: .5rem;
        
        @include margin-bottom(1);
        
        @include mq(bp4) {
            @include margin-bottom(0);
        }
        
        :lang(ja) & {
            display: flex;
            align-items: center;
            
            @include margin-bottom(0);
            @include margin-top(.5);

        }
        
    }
    
    :lang(ja) & {
        margin: 0 auto ;
        
    }
}

.c-def-list__description-quote{
    border: 0;
    margin-left: 0;
    font-style: italic;
    font-size: 1.3rem;
    line-height: 1.6;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 1rem;
    
    
    
    @include mq(bp2){
        margin-top: 2rem;
        margin-bottom: 0;
        // border: 1px solid red;
        // border: 0;
        width: 44rem;
        max-width: calc(100vw - 80px);
    }
    
    @include mq(bp3) {
        font-size: 1.5rem;
        //   line-height: 1.5;
        margin-top: 4rem;
        max-width: calc(100vw - 140px);
        
        margin-bottom: 2rem;
        // border: 1px solid blue;
    }
    
    @include mq(bp4) {
        width: 44rem;
        margin-top: 6rem;
        // max-width: 100%;
        margin-left: 17%;
        border: 0;
        
        margin-left: 0;
        // line-height: 1.3;
        margin-bottom: 2rem;
    }
    
}