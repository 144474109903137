/*
    Components: Pagination
    ---
    Default pagination with a centered option.
*/

.c-pagination {
    display: flex;
    position: relative;
    background: palette(color-background, light);
    font-family: $beta-font-family;

    border-top: $border-width $border-style palette(color-neutral,light);

    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        text-align: justify;
    }

}

.c-pagination--centered {
    justify-content: center;
}

    .c-pagination__list {
        flex: 1 0 auto;
        justify-content: center;
        width: 80%;
        max-width: 700px;

        @include margin-top(1);
        @include margin-bottom(1);

        @include mq(bp3) {
            justify-content: space-between;
        }
    }

    .c-pagination__item {

        display: none;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 80px;

        @include mq(bp3) {
            display: flex;
        }

        &:first-child,
        &:last-child {
            display: flex;
            flex: 0 0 150px;
        }

        & + & {
            border-left: 1px dotted palette(color-neutral);
        }
    }

    .c-pagination__link {
        padding: 1rem .5rem;
        width: auto;
        min-width: 37px;
        text-align: center;
    }
