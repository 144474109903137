/*
    Objects: List
    ---
    List object with no bullets
*/

.o-list {
    list-style: none;
    padding-left: 0;
}

.o-list--horizontal {
    display: flex;
}

    .o-list__item {
        .o-list--horizontal & {
            // float: left;
        }
    }
