/*
    Utilities: States
    ---
    Component states
*/

.is-hidden {
    display: none !important;
}

// Loop through the different breakpoints
@each $breakpoint, $value in $breakpoints {
    @media (min-width: $value) {
        .is-hidden-from-#{$breakpoint} {
            display: none !important;
        }
    }
}

@each $breakpoint, $value in $breakpoints {
    @media (max-width: $value) {
        .is-hidden-until-#{$breakpoint} {
            display: none !important;
        }
    }
}
