/*
    Components: Symbols
    ---
    https://css-tricks.com/svg-symbol-good-choice-icons/
*/

// Symbol
.c-symbol {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    vertical-align: middle;
}

.c-symbol--180-deg {
    transform: rotate(180deg);
}
