/*
    Components: Thumbnails
    ---
    Used for the photo gallery
*/

.c-thumbnails {
    flex-wrap: wrap;
    display: flex;
    // justify-content: space-around;
    // width: auto;
    max-width: 540px;
    padding-left: 2%;
    // max-height: 290px;
    // margin: 0;

    // let the pictures come:
    // flex-direction: column;
    // overflow-x: auto;

    // @include mq(bp2) {
    //     max-width: 100%;
    //     margin: 1rem;

    // @include mq(bp4) {
    //         max-width: 520px;
    //         max-height: 365px;
    //     }
    // }
    padding-top: 1rem;
    background-color: palette(color-background);
    :lang(ja) & {
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.c-thumbnails--in-gallery {
    padding-left: 0;
    margin: auto;
    justify-content: center;
    @include margin-bottom(2)

    .c-thumbnail:nth-child(3n) {
        margin-right: 0;
    }
}

// on bigscreens:
// temp0.scrollLeft += 171

.c-thumbnail {
    // width: 100%;
    width: 31%;
    margin-right: 2%;
    max-width: 165px;

    // margin: 0 .3rem;
    // max-width: 123px;
    // //very small
    // @include mq(bp4) {
    //     margin: 0 .3rem;
    //     max-width: 163px;
    // }
    //
    // &:first-child,
    // &:nth-child(2) {
    //     margin-left: 0;
    // }
}

.c-thumbnail__wrapper {
    position: relative;
    padding-bottom: 60%;
    height: 0;
    margin-bottom: 10px;
    border: 7px solid #fff;
    cursor: pointer;
}
