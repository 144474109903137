/*
    Components: Footer
    ---
    Components with the currently used items
*/

.c-footer {
    background-color: palette(color-background,x-dark);

    @include padding-tb($alpha-leading, $alpha-leading);
    @include padding-lr($alpha-leading);

    min-height: 163px;
    height: auto;
}

.c-footer__logo {
    @include margin-lr(.25,0);
}

.c-footer__title {
    font-family: $beta-font-family;
    color: palette(color-neutral,light);
    letter-spacing: 0;

    @include text-dimensions($ms2,1,0,.9,.75);
    @include margin-bottom(0);
    @include margin-lr(1,0);


}

    .c-footer__tagline {
        font-family: $beta-font-family;
        font-weight: $beta-font-weight;

        @include margin-top(.5);
        @include text-dimensions($ms-1,.75);

        :lang(ja) & {
            font-family: $beta-jp-font-family;
            // letter-spacing: $alpha-jp-letter-spacing;
            // font-weight: $alpha-font-weight;
        }
    }

.c-footer__nav {
    flex: 1 auto;

    // @include margin-lr(1,0);

    // @include mq(bp3) {
    //     // justify-content: center;
    // }

    .o-list {
        @include margin-bottom(0);
    }

    .c-footer__nav-link {
        display: block;
        // font-size: .75rem;

        // border-bottom: 1px dashed transparent;
        color: palette(color-neutral);

        @include padding-tb(.75);
        @include margin-lr(.75);

        &:visited {
            color: palette(color-neutral);
        }

        &:hover {
            text-decoration: $link-decoration;
            color: palette(color-background, x-light);
            // border-bottom: 1px dashed palette(color-neutral,dark);
        }
    }

    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
    }

}
