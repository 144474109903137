.c-work {
    background-color: palette(color-background);
    position: relative;
    @include padding-tb(6,3);



    & + & {
        @include padding-tb(3,3);
        // @include padding-tb(4,2);
    }

    &:last-of-type {
        @include padding-tb(3,5);
    }

    @include mq(bp4, max) {
        @include padding-tb(2,1);

        & + & {
            @include padding-tb(2,2);
            // @include padding-tb(4,2);
        }

        &:last-of-type {
            @include padding-tb(2,3);

        }
    }

    & + &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;

        border-top: 1px dashed  palette(color-background, dark);
    }

    .c-card__expandable > p:nth-last-child(2) {
        // p:nth-last-child(2) {
        padding-bottom: 1.9rem;
        // }
    }
}

.c-work--logo {
    min-height: auto;

    @include mq(bp3) {
        min-height: calc(100vh - 384px);
    }

    @include mq(bp5) {
        min-height: calc(100vh - 411.5px);
    }
}


.c-work--conditions {
    // margin: 0 auto;
    // min-height: calc(100vh - 163px - 90px);
    min-height: calc(100vh - 377px);
    height: auto;

    .c-work__title {
        @include margin-top(1.5);

        :lang(ja) & {
            font-family: $alpha-jp-font-family;
            // letter-spacing: 0;
        }
    }

    .c-work__description {
        @include margin-bottom(.75);
        font-family: $beta-font-family;

        :lang(ja) & {
            font-family: $beta-jp-font-family;
            // letter-spacing: 0;
        }
    }

    @include mq(bp3) {
        min-height: calc(100vh - 303px);
    }

    @include mq(bp5) {
        min-height: calc(100vh - 320.5px);
    }

    @include mq(bp5, max) {
        @include padding-tb(1.5,2);
    }
}

.c-work--default {

    @include padding-tb(2,4);

    @include mq(bp4) {
        @include padding-tb(4,5);
    }
}

.c-work--antiques {
    min-height: auto;
    
    @include padding-tb(4,0);
}

.c-work__description {
    @include margin-bottom(.75);
    font-family: $beta-font-family;

    :lang(ja) & {
        font-family: $beta-jp-font-family;
        // letter-spacing: $alpha-jp-letter-spacing;
        // font-weight: $alpha-font-weight;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
    }


}

.c-work--contact {
    background-color: palette(color-background);
    position: relative;
    // @include padding-tb(6,4);
    @include padding-tb(2,1);
    min-height: calc(100vh - 303px);
    height: auto;

    @include mq(bp5) {
        @include padding-tb(2,1);
        min-height: calc(100vh - 320.5px);
    }
}

    .c-work__img-left {

        @include mq(bp3, max) {
            @include padding-lr(0,0);
            margin: 0;

            width: 100%;
            order: 1;
        }

        @include mq(bp3) {
            width: 50%;
            margin-left: -1rem;
            margin-right: 1rem;

        }

        @include mq(bp4) {
            margin-left: -5%;
            margin-right: 5%;
        }

        @include mq(bp5) {
            margin-left: -10%;
            margin-right: 10%;
        }

        @include mq(bp6) {
            margin-left: -15%;
            margin-right: 15%;
        }
    }

    .c-work__text-right {
        @include mq(bp3, max) {
            @include padding-lr(0,0);

            width: 100%;
            order: 0;
        }

        @include mq(bp3) {
            width: 50%;
            @include padding-lr(2,2);
        }

        @include mq(bp4) {
            margin-left: -3%;
            margin-right: 3%;
        }

        @include mq(bp5) {
            margin-left: -4%;
            margin-right: 4%;
        }

        @include mq(bp6) {
            margin-left: -7%;
            margin-right: 7%;
        }
    }



    .c-work__img-right {
        order: 2;

        @include mq(bp3, max) {
            margin: 0;
            width: 100%;
            order: 1;

            @include padding-lr(0,0);
        }

        @include mq(bp3) {
            width: 50%;
            margin-right: -1rem;
            margin-left: 1rem;
        }

        @include mq(bp4) {
            margin-right: -5%;
            margin-left: 5%;
        }

        @include mq(bp5) {
            margin-right: -10%;
            margin-left: 10%;
        }

        @include mq(bp6) {
            margin-right: -15%;
            margin-left: 15%;
        }
    }

    .c-work__form-right {

        order: 2;

        @include mq(bp3, max) {
            margin: 0;
            width: 100%;
            order: 1;

            @include padding-lr(0,0);
        }

        @include mq(bp3) {
            width: 50%;
            @include margin-lr(0);
        }

        .c-contact & .c-card--beta {
            max-width: auto;


            @include mq(bp3) {
                max-width: 50ch;
            }
        }
    }

    .c-work__text-left {
        order: 1;

        @include mq(bp3, max) {
            @include padding-lr(0,0);

            width: 100%;
            order: 0;
        }

        @include mq(bp3) {
            width: 50%;
            @include padding-lr(2,2);
        }

        @include mq(bp4) {
            margin-right: -3%;
            margin-left: 3%;
        }

        @include mq(bp5) {
            margin-right: -4%;
            margin-left: 4%;
        }

        @include mq(bp6) {
            margin-right: -7%;
            margin-left: 7%;
        }

        &.c-work__text-resume {
            // width: 66%;
            order: 5;
            margin: 2rem auto 0;

            @include mq(bp3){
                margin-bottom: 2rem
            }
        }
    }

    .c-work__text-left-beta {
        @include mq(bp3) {
            width: 100%;
        }
    }


.c-work--custom{
    display: flex;
    flex-wrap: wrap;
    padding-top: 3rem;
    padding-bottom: .5rem;

    &:last-of-type{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    
    @include mq(bp5){
        display: block;
    }

    & + .c-work--custom{
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .c-card__body-custom{
        li > strong:last-child{
            // margin-bottom: 1rem;
            display: inline-block;
        }
     }

     p {
        margin-bottom: 0;
     }

     li{
        margin-bottom: 0.7rem;
        
        ol{
             li{
                 padding-left: 0.5rem;
                 margin-left: -1em;

             }
         }
     }
}

.c-work--custom-link-top{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    small{
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
    }



}

.c-work--custom-img{
    float: none;
    position: relative;
    margin: auto;
    width: 100% !important;
    max-width: 36rem;
    order: 1;
    
    @include mq(bp5){
        float: right;
        position: sticky;
        top: 0;
        align-self: flex-start;
    }

    .c-card--custom-img{
        margin-right: 0;
        margin-left: 0;

        @include mq(bp5){
            margin-left: -90px;
            max-width: none;
            margin-right: -2%;
        }

        @include mq(bp4){
        }

        @include mq(bp6){
            margin-right: -4vw;
        }

        @include mq(bp7){
            margin-right: -6.5vw;
        }


    }

    .c-thumbnails--custom-img{
        max-width: none;
        margin-left: 0;
        margin-top: 0;
        padding-left: 2%;

        @include mq(bp5){
            max-width: none;
            margin-left: -8%;
            margin-top: 1%;
        }
    }

    
}

.c-work--custom-txt{
    width: 100% !important;
    margin: auto;
    padding: 0 5%;
    order: 0;

    @include mq(bp5){
        margin-left: 3%;
        padding-left: 0;
    }

    .c-card--custom-title{
        position: relative;
        margin: 0 0 20px;
        padding: 0;
        @include mq(bp5){
            position: sticky;
            top: 0;
            background: #f7f3f0;
            margin: 0 100px 0 -43px;
            padding: 10px 0 10px 50px;
        }
    }
    
    ul{
        // padding-left: 0;
        // margin-left: 0;
        columns: 2;

        @include mq(bp5){
            columns: 1;
        }

        > li{
            width: 100%;
            padding-right: 105px;
            margin-bottom: 10px;
            padding-left: 10px;
        }
    }
}