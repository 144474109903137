.c-page--about{


  .c-row--portfolio{
    &:not(:first-of-type){
      .c-work:first-child{
        border-top: $border-width $border-style palette(color-neutral,light);
      }
    }

    &:first-of-type{

      .c-work__img-right{
        width: 290px;
        margin: 20px auto;
        align-self: flex-start;
        max-width: none;

        @include mq(bp2){
          max-width: 30%;
        }

        @include mq(bp3){
          max-width: 40%;
        }

        @include mq(bp4){
          max-width: none;
        }
      }
      .c-work:first-child:last-child{
        padding-bottom: 0;
      }

      .c-work__text-left{
        @include mq(bp2){
          width: 70%;
          padding-right: 1rem;
          // padding-left: 1rem;
        }

        @include mq(bp3){
          width: 60%;
        }
      }
    }

    .c-work{
      padding-top: 2rem;

      .c-card{
        margin-bottom: 0;
      }
      
      @include mq(bp4){
        padding-top: 4rem;
      }      

    }
    .c-work + .c-work{
      padding-top: 0;
      padding-bottom: 1rem;

       @include mq(bp4){
         padding-top: 4rem;
       }
    }
  }


}

.c-page-about__profile-img{
  max-width: 258px;
}