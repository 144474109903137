/*
    Components: Brand
    ---
    Brand image/logo
*/

/**
 *  1. Needs to be the width of the logo you are using.
 */

.c-logo {
    float: left;
    display: block;
    margin-bottom: 0;
    background-repeat: no-repeat;

    background-image: url('../img/svg/logo.svg');

    font-family: $beta-font-family !important;
    letter-spacing: -.05rem !important;
    text-indent: -99999px;
    width: 100%;
    background-position: center;

    @include padding-left(4.5);
    @include text-dimensions($h3-font-size,1,0,1.75,.75);

    // :lang(ja) & {
    //     @include text-dimensions($h3-font-size,1,0,1.75,1.75);
    // }

    @include mq(bp1) {
        width: auto; /* [1] */
        background-position: center left;
        text-indent: 0;

        @include padding-bottom(1.75);
    }

    @include mq(bp5) {
        @include text-dimensions($h1-font-size,1,0,1.75,1.75);

        // :lang(ja) & {
        //     @include text-dimensions($h1-font-size,1,0,1.75,1.75);

        // }
    }
}

    .c-logo--centered {
        background-position: center;
    }

    .c-logo--neg {
        background-image: url('../img/svg/logo-neg.svg');
    }

    .c-logo--white-neg {
        background-image: url('../img/svg/logo-white-neg.svg');
    }

    .c-logo__link {
        display: block;
        color: palette(color-beta, dark);

        width: 100vw;
        max-width: 100%;
        height: 50%;
        z-index: 6;
        flex: 1 0 auto;

        @include mq(bp1) {
            width: auto;
        }

        &:hover,
        &:active,
        &:visited {
            color: palette(color-beta, dark);
            text-decoration: none;
        }
    }

    .c-logo__link-footer {
        display: flex;
        flex: unset;
    }
